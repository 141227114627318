.new-header {
  background-color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 15px;
  border-bottom: 1px solid #ddd;
}

.new-header-left {
  display: flex;
  align-items: center;
}

.new-header-left img {
  cursor: pointer;
  margin-right: 10px;
}

.new-recipient-name {
  font-size: 16px;
  font-weight: bold;
  color: #333;
}

.new-header-right {
  display: flex;
  align-items: center;
  gap: 10px;
}

.new-header-right img {
  width: 24px;
  height: 24px;
  cursor: pointer;
}

.new-chat-container {
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  overflow-x: hidden;
  max-height: 530px;
  min-width: 100%;
  padding: 10px;
  border: none;
}

@media (max-width: 768px) {
  .new-chat-container {
    max-width: 100px;
  }
}

.new-chat-footer {
  display: flex;
  align-items: center;
  padding: 10px;
  border-top: 1px solid #ddd;
  width: 100%;
  box-sizing: border-box;
  flex-shrink: 0;
}

.new-chat-footer textarea {
  flex-grow: 1;
  padding: 3px;
  border: 1px solid #ccc;
  border-radius: 5px;
  resize: none;
  font-size: 14px;
  min-height: 25px;
  max-height: 50px;
}

.new-send-button {
  background: none;
  border: none;
  cursor: pointer;
  margin-left: 10px;
}

.new-send-button img {
  width: 44px;
  height: 44px;
}

.chat-r,
.chat-l {
  width: 100%;
  max-width: 100%;
  padding: 5px;
  box-sizing: border-box;
  display: flex;
  align-items: flex-start;
}

.chat-r {
  justify-content: flex-end;
  font-size: 14px;
  line-height: 1.4;
}

.chat-l {
  justify-content: flex-start;
}

.mess {
  max-width: 80%;
  word-wrap: break-word; /* Ensures words break if needed */
  overflow-wrap: break-word; /* Ensures long words wrap */
  padding: 10px 14px;
  border-radius: 12px;
  margin: 4px 0;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
}
 .temp {
  white-space: pre-wrap; /* Preserves formatting and allows wrapping */
  word-wrap: break-word; /* Breaks long words if necessary */
  overflow-wrap: break-word; /* Ensures text doesn't overflow */
  max-width: 300px;
}



.mess-r {
  background-color: #e6eef0;
  color: #333;
  font-size: 14px;
  line-height: 1.4;
}

.mess:not(.mess-r) {
  background-color: #f0f0f0;
  color: #333;
}

.mess-content {
  width: 100%;
  word-break: break-word; /* Ensures words break when necessary */
  overflow-wrap: break-word; /* Ensures long words wrap properly */
  white-space: normal; /* Allows text to wrap to the next line */
  font-size: 14px;
  line-height: 1.4;
}


.check {
  font-size: 12px;
  color: #c7c3c3;
  text-align: right;
  margin-top: 4px;
}

@media (max-width: 768px) {
  .mess {
    max-width: 90%;
    padding: 8px 12px;
    font-size: 13px;
  }

  .mess-content {
    font-size: 13px;
  }

  .check {
    font-size: 11px;
  }
}

@media (max-width: 480px) {
  .mess {
    max-width: 95%;
    padding: 6px 10px;
    font-size: 12px;
  }

  .mess-content {
    font-size: 12px;
  }

  .check {
    font-size: 10px;
  }
}

/* Add these styles to your CSS file */
.document-container {
  display: flex;
  background-color: #f5f5f5;
  border-radius: 8px;
  padding: 10px;
  margin-bottom: 10px;
  width: 100%;
  max-width: 300px;
}

.document-link {
  display: flex;
  text-decoration: none;
  color: inherit;
  width: 100%;
}

.document-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  background-color: #4873f4;
  border-radius: 4px;
  margin-right: 10px;
  color: white;
}

.document-icon i {
  font-size: 20px;
  color: #555;
}

.document-info {
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
}

.document-name {
  font-weight: bold;
  font-size: 14px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.document-type {
  font-size: 12px;
  color: #777;
}