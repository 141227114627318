/* Styling for the selected user info at the top */
.selected-user-info {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px;
  border-radius: 10px;
  background: #fff;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.selected-user-info img {
  height: 50px;
  width: 50px;
  border-radius: 50%;
  object-fit: cover;
}

.selected-user-info h6 {
  font-size: 16px;
  font-weight: bold;
}

.selected-user-info small {
  color: #6c757d;
}

/* Chat list styling */
.chat-list-container {
  width: 100%;
  height: 85vh;
  overflow-y: auto;
  border: 1px solid #ddd;
  background: #fff;
  border-radius: 10px;
  padding: 10px;
}

.chat-item {
  display: flex;
  align-items: center;
  padding: 10px;
  margin-bottom: 8px;
  border-radius: 10px;
  background-color: #ffffff;
  transition: background 0.3s;
  cursor: pointer;
  min-width: 200px;
}

.chat-item:hover {
  background-color: #d037ef;
}

.chat-item.active {
  background-color: #000000;
}

.chat-item img {
  height: 50px;
  width: 50px;
  border-radius: 50%;
  object-fit: cover;
  margin-right: 10px;
}


.chat-item .status-dot {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  position: absolute;
  top: 8px;
  left: 40px;
}

.chat-item .status-dot.online {
  background-color: #28a745;
}

.chat-item .status-dot.offline {
  background-color: #dc3545;
}

.chat-item .message-count {
  background: #ff3b30;
  color: #fff;
  font-size: 12px;
  font-weight: bold;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: 10px;
  top: 12px;
}

.chat-item .time-stamp {
  font-size: 12px;
  color: #adb5bd;
  position: absolute;
  right: 10px;
  bottom: 10px;
}
