.signup-model {
    width: 80% !important;
}

@media (min-width: 768px) {
    .signup-model {
        width: 40% !important; 
    }
}

.tooltip {
    position: absolute;
    top: 100%;
    right: 0;
    transform: translateX(0%);
    background: #f9fafb;
    padding: 5px 10px;
    border-radius: 6px;
    /* border: 1px solid #333; */
    z-index: 1000;
    display: none;
    width: 70vw;
    max-width: 800px;
}

.welcome-container {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    gap: 3rem;
    justify-content: center;
    align-items: center;
}
  
.welcome-container .group:hover ~ .tooltip {
    display: block;
}

@media (max-width: 768px) {
    .tooltip {
      width: 90vw;
      left: 0;
      margin-top: 1rem;
    }
  }