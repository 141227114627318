.overflow {
    height: 100%;
    overflow: hidden;
}

/* .blur {
    filter: blur(3px);
} */

.modal {
    position: fixed;
    overflow-y: hidden;
    z-index: 999;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.153);
    display: flex;
    justify-content: center;
    align-items: center;
}

.modal-content {
    border-radius: 10px;
    background-color: #fefefe;
    margin: 15% auto;
    padding: 20px;
    
    /* border: 1px solid #888; */
    width: 40%;
    overflow: auto;
    z-index: 10;
    padding-bottom: 50px !important;
	box-sizing: border-box; /* Ensures padding and borders are included in width/height */
	will-change: transform; /* Optimizes rendering */
	-webkit-font-smoothing: antialiased; /* Improves text rendering */
	transform: translateZ(0);
}

.modal-content-wide {
    width: 70%;
    height: 90vh;
    /* max-height: 90vh;  */
  }
.modal-content-message {
    width: 40%;
    height: 95vh;
    /* max-height: 90vh;  */
  }
  /* Adjust for tablets and smaller screens */
@media (max-width: 1024px) { /* For tablets and smaller */
    .modal-content-wide {
      width: 90%; 
      /* max-height: 80vh; */
    }
  }
  
  /* Further adjust for mobile devices */
  @media (max-width: 640px) {
    .modal-content-wide {
      width: 100%; 
      /* max-height: 70vh;  */
    }
  }

.chat-modal-content-wide {
    width: 40%;
    height: 90vh;
    /* max-height: 90vh;  */
  }
  /* Adjust for tablets and smaller screens */
@media (max-width: 1024px) { /* For tablets and smaller */
    .chat-modal-content-wide {
      width: 80%; 
      /* max-height: 80vh; */
    }
  }
  
  /* Further adjust for mobile devices */
  @media (max-width: 640px) {
    .chat-modal-content-wide {
      width: 95%; 
      /* max-height: 70vh;  */
    }
  }

  .simplebar-container {
    max-height: 73vh; 
    overflow-y: auto; 
    box-sizing: border-box;
    padding: 20px;
  }
  
  /* @media (max-width: 1024px) { 
    .simplebar-container {
      max-height: 65vh; 
    }
  }
  
  @media (max-width: 640px) {
    .simplebar-container {
      max-height: 45vh; 
    }
  } */
.close {
    color: #aaaaaa;
    float: right;
    font-size: 28px;
    font-weight: bold;
}

.close:hover,
.close:focus {
    color: #000;
    text-decoration: none;
    cursor: pointer;
}

label {
  justify-content: center;
  align-items: center;
  justify-items: center;
  text-align: center;
}

select,
select > option {
  justify-content: start;
  align-items: start;
  justify-items: start;
  text-align: start;
}

.projectnotfound {
    margin-top: -200px;
}


.chat-container{
	width: 290px;
	margin: auto;
    height: 500px;
	box-shadow: 5px 5px 12px 5px rgba(216, 215, 215, 0.712);
	border:10px solid rgb(255, 250, 250);
	border-radius: 40px;
}
.chat-container-large{
	width: 300px;  /* 400*/
    height: 600px; /* 750*/
}


.chat {
	display: flex;
	flex-direction: column;
	height: 100%;
	background: #f7f7f7;
}
.chat-header {
	display: flex;
	cursor: pointer;
    
}
.profile {
	width: 100%;
	background: #036055;
	display: flex;
	align-items: center;
	height: 60px;
	padding: 0px 4px;
	position: relative;
}
.profile .pp {
	width: 50px;
	display: inline-block;
	border-radius: 50%;
	margin-left: 32px;
}
.profile .arrow {
	display: inline-block;
	width: 30px;
	position: absolute;
	top: 19px;
	cursor: pointer;
}
.profile h2 {
	display: inline-block;
	line-height: 60px;
	vertical-align: bottom;
	color: #fff;
	font-size: 20px;
}
.profile span {
	color: #ccc;
	position: absolute;
	top: 42px;
	left: 100px;
	font-size: 14px;
}
.right {
	display: flex;           /* Aligns items in a row */
	gap: 15px;               /* Adds space between each icon */
	justify-content: center; /* Centers icons within the container (optional) */
	align-items: center;
}
.right .icon {
	display: inline-block;
	width: 20px;
	margin-right: 10px;
	height: 20px;
}
.right .phone {
	display: inline-block;
	width: 20px;
	margin-right: 3px;
	height: 16px;
}
.profile .left {
	flex: 1;
	margin-left: 5px;
}

.chat-box {

	background-size: cover;
	padding: 0px 20px;
	overflow-y: auto;
	flex: 1;
	/* max-height: 60vh; */
	max-width: 80vw; 
}
.chat-box .img_chat {
	width: 280px;
}

.chat-r {
	display: flex;
}
.chat-r .sp {
	flex: 1;
}

.chat-l {
	display: flex;
}
.chat-l .sp {
	flex: 1;
}

.chat-box .mess {
	max-width: 80%;
    min-height: 10px;
    width: 220px;
	background: #ffffff;
	/* padding: 10px; */
	/* padding-bottom: 20px; */
	border-radius: 10px;
	cursor: pointer;
	position: relative;
    word-wrap: break-word; /* Ensures long words break within the max-width */
    display: inline-block;
    overflow-wrap: break-word; /* Ensures proper wrapping of long words */
    box-sizing: border-box;
	font-size: 10px; /* Adjust font size for WhatsApp-like appearance */
    line-height: 1.4; /* Increased line height for better readability */
    color: #111;
}
.chat-box .mess .mess-content {
	margin: 10px;
	
}
.chat-box .mess pre {
	white-space: pre-wrap; /* Preserves whitespace and ensures line breaks */
    word-break: break-word;
	max-width: 100%;
	font-size: 13px;
}
.chat-box .mess-r {
    background: #E2FFC7;
}
.chat-box .emoji {
	width: 20px;
}
.chat-box .check {
	float: right;
	/* position: absolute; */
	/* white-space: nowrap; */
	/* bottom: 5px;
	right: 7px; */
	white-space: pre-line;
	/* padding: 10px 20px 10px 12px;  */
}
.chat-box .check img {
	width: 20px;
}
.chat-box .check span {
	color: #888;
	font-size: 12px;
	font-weight: 700px;
}

*::-webkit-scrollbar {
	width: 7px;
}
*::-webkit-scrollbar-track {
	background: #e9e9e9;
	border-radius: 10px;
}
*::-webkit-scrollbar-thumb {
	background: #aaa;
	border-radius: 10px;
}
*::-webkit-scrollbar-thumb:hover {
	background: #acacac;
}

.chat-footer {
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 60px;
	position: relative;
	cursor: pointer;
	height: 50px;
}
.chat-footer textarea {
	display: block;
	flex: 1;
	width: 100%;
	height: 35px;
	border-radius: 60px;
	margin: 5px;
	padding: 5px;
	outline: none;
	font-size: 14px;
	padding-left: 30px;
	padding-right: 10px;
	border: 2px solid #ccc;
	color: #555;
	resize: none;
	overflow: hidden;
}
.chat-footer .mic {
	display: block;
	width: 30px;
	height: 30px;
	margin-right: 4px;
	/* margin-right: 5px;  */
	/* margin-top: 8px; */
}
.chat-footer .mic-background {
	background-color: #036055;
	width: 30px;           /* Adjust as needed */
	height: 30px;          /* Same as width for a perfect circle */
	border-radius: 50%;    /* Makes it a circle */
	display: flex;         /* Centers content inside */
	align-items: center;   
	justify-content: center;
}

.chat-footer .emo{
	display: block;
	width: 22px;
	height: 22px;
	position: absolute;
	left: 10px;
	top: 14px;
}
.chat-footer .icons {
	position: absolute;
	right: 50px;
	top: 15px;
}
.chat-footer .icons div{
    display: inline-block;
    width: 23px;
    height: 23px;
    margin-left: 3px;
}